<template>
    <FocusTrap>
      <div class="card">
      <div class="card-header header-elements-inline">
        <legend class="font-weight-semibold text-uppercase font-size-sm">
          <i class="icon-file-text2"></i>
          Enter Supplier information
        </legend>

        <div class="header-elements">
          <div class="list-icons">
            <a class="list-icons-item" data-action="reload"></a>
            <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
          </div>
        </div>
      </div>

      <div class="card-body">

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Name:</label>
          <div class="col-md-10">
            <input type="text" id="txtname" ref="txtName" class="form-control" placeholder="Supplier Name" v-if="supplier" v-model="supplier.name" autocomplete="off" autofocus >
          </div>
        </div>


        <div class="form-group row">
          <label class="col-md-2 col-form-label">Street:</label>
          <div class="col-md-10">
            <textarea rows="3" class="form-control" placeholder="Enter address here" v-if="supplier" v-model="supplier.street"></textarea>
          </div>
        </div>


        <div class="form-group row">
          <label class="col-md-2 col-form-label">City:</label>
          <div class="col-md-4">
            <input type="text" class="form-control" placeholder="City" maxlength="50" autocomplete="off" v-if="supplier" v-model="supplier.city">
          </div>

          <label class="col-md-2 col-form-label">PIN:</label>
          <div class="col-md-4">
            <input type="text" class="form-control" placeholder="Pincode" maxlength="7" v-if="supplier" v-model="supplier.pin">
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Tel:</label>
          <div class="col-md-4">
            <input type="text" class="form-control" placeholder="Telephone" maxlength="10" autocomplete="off" v-if="supplier" v-model="supplier.tel">
          </div>

          <label class="col-md-2 col-form-label">GSTIN:</label>
          <div class="col-md-4">
            <input type="text" class="form-control" placeholder="GSTIN" maxlength="15" v-if="supplier" v-model="supplier.gstin">
          </div>
        </div>


        <div class="text-right">
          <button type="submit" class="btn btn-primary legitRipple" @click="saveDocument">Save <i class="icon-paperplane ml-2"></i></button>
        </div>

      </div>
    </div>
    </FocusTrap>
</template>

<script>
import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'
export default {
  name: 'SupplierForm',
  store,
  data() {
    return {
      supplier: JSON.parse('{"id":0,"status":"Active","type":0,"name":"","street":"","city":"","pin":"","state_cd":33,"tel":"","mobile":"","email":"","web":"","gstin":"","open_bal":0}')
    }
  },
  component: {

  },
  props: {
    mysupplier: {
      type: Object,
      required: true,
      default: JSON.parse('{"id":0,"status":"Active","type":0,"name":"","street":"","city":"","pin":"","state_cd":33,"tel":"","mobile":"","email":"","web":"","gstin":"","open_bal":0}')
    }
  },
  beforeMount () {
    this.supplier = this.mysupplier; // save props data to itself's data and deal with tms

    // this.supplier.opening_balance = Math.abs(this.supplier.opening_balance);
  },
  created () {
    this.$data.supplier = JSON.parse('{"id":0,"status":"Active","type":0,"name":"","street":"","city":"","pin":"","state_cd":33,"tel":"","mobile":"","email":"","web":"","gstin":"","open_bal":0}')
  },
  mounted () {
    const self = this;

    this.$refs.txtName.focus();
  },
  methods: {
    closeWindow(){
      this.$emit('supplier_window_closed');
      // if(this.supplier.id > 0){
      //   this.supplier = JSON.parse('{"id":0,"status":"Active","type":0,"name":"","street":"","city":"","pin":"","state_cd":33,"tel":"","mobile":"","email":"","web":"","gstin":"","open_bal":0}');
      // } else {
      //   this.$router.push("/home");
      // }
    },
    saveDocument(){

      const self = this;

      // self.$data.supplier.type = parseInt(self.$data.supplier.type);
      if(self.$data.supplier.name.toString().length < 5){
        self.$refs.txtName.focus();
        alert("Invalid Name");
        return;
      }

      self.$data.supplier.state_cd = 33;
      const requestOptions = {
        method:  (self.$data.supplier.id == 0 ? 'POST' : 'PUT'),
        mode:"cors",
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.supplier)
      };

      fetch(`${process.env.VUE_APP_ROOT_API}api/bms/supplier`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          swal ( { title: "Success!" ,  text: resp.msg, type:  "success"} );
          self.supplier = JSON.parse('{"id":0,"status":"Active","type":0,"name":"","street":"","city":"","pin":"","state_cd":33,"tel":"","mobile":"","email":"","web":"","gstin":"","open_bal":0}');
          self.$refs.txtName.focus();
          self.$emit('supplier_saved', resp.data);
        } else {
          swal ( { title: "Oh noes!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      });
    }
  }
}
</script>

<style scoped>
  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
